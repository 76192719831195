@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.prompt-thin {
  font-family: 'Prompt', serif;
  font-weight: 100;
  font-style: normal;
}
.product-card-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  max-width: 250px; 
    margin: 10px;
  }
  
  .product-card {
    width: 100%;
    height: 400px;
    margin: 10px;
    padding: 10px;
    transition: all 0.3s ease; 
  }
  .promotion-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    z-index: 1;
    animation: blink 1s infinite;
  }

  .product-price h4 {
    margin-top: 5px;
    color: #4CAF50;
    font-weight: bold;
    font-size: 16px; 
  }

  .product-card.hovered {
    transform: translateY(-10px); /* ยกการ์ดขึ้น 10px */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* เพิ่มเงาให้ดูลอยขึ้น */
  }
  @keyframes blink {
    0% { background-color: red; }
    50% { background-color: pink; }
    100% { background-color: red; }
  }
  
  @media (max-width: 768px) {
    .product-card {
      width: 48%; 
      font-size: 0.9rem; 
    }
  
    .product-card-title {
      font-size: 1rem; 
    }
  
    .product-price {
      font-size: 0.8rem; 
    }
  
    .product-card-body {
      padding: 5px; 
    }
  }
  
 
  @media (max-width: 576px) {
    .product-card {
      width: 120px; 
      font-size: 0.8rem; 
    }
  
    .product-card-title {
      font-size: 0.9rem; 
    }
  
    .product-price {
      font-size: 0.7rem;
    }
  
    .product-card-body {
      padding: 5px; 
    }
    .product-card-body .btn {
        font-size: 0.4rem; 
        padding: 5px 10px; 
      }
    .product-card-footer .btn {
        font-size: 0.6rem; 
        padding: 5px 10px; 
      }
      .product-card {
        width: 100%;
        height: 230px;
        margin: 5px;
        padding: 10px;
        transition: all 0.3s ease; 
      }
  }
  